import EventSignupModel from 'Models/Email/EventSignupModel.interface';

export async function EventSignUp(details: EventSignupModel) {
  const res = await fetch(`/email/SendEventSignupRequest`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(details),
  });

  if (res.ok) {
    return res;
  }
}
