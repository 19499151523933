import Button from 'Atoms/Buttons/Button';
import Image from 'Atoms/Image/Image';
import Input from 'Atoms/Input/Input';
import { InputValidation } from 'Atoms/Input/InputValidation';
import { H4 } from 'Atoms/Typography/Headings/Heading';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';
import EventSignupModel from 'Models/Email/EventSignupModel.interface';
import EventPageModel from 'Models/Pages/EventPage/EventPageModel.interface';
import HtmlArea from 'Molecules/XHtmlArea/XHtmlArea';
import SmallHeroBlock from 'Organisms/Blocks/SmallHeroBlock';
import { useState } from 'react';
import { accountFormValidation } from 'Shared/Common/AccountFormValidation';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import useMedia from 'Shared/Hooks/useMedia';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { EventSignUp } from './Event';

function EventPage() {
  const {
    heading,
    inEditMode,
    introText,
    subheading,
    bodyText,
    image,
    startDate,
    heroImage,
    startTime,
    endDate,
    endTime,
    showSignUp,
    isFullyBooked,
    location,
  } = useCurrentPage<EventPageModel>();
  const {
    accountLabels: { email: emailLabel, givenName, phoneNumber },
    eventLabels: { isFullyBookedText, eventSubmitted, signUp, to },
    validationLabels,
  } = useTranslationData();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNbr, setPhoneNbr] = useState<string>('');
  const [formValid, setFormValid] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const { languageRoute } = useAppSettingsData();
  const isMobile = useMedia(mediaQueryTypes.mediaMaxSmall);

  const SignUpForEvent = async () => {
    const res = await EventSignUp({
      email: email,
      eventId: heading,
      fullName: name,
      language: languageRoute,
      personalNumber: '',
      phone: phoneNbr,
    } as EventSignupModel);

    if (res?.ok) {
      setHasSubmitted(true);
      setTimeout(() => {
        setHasSubmitted(false);
      }, 5000);
    }
  };

  const onInputsValid = () => {
    setFormValid(true);
  };

  const onInputsInvalid = () => {
    setFormValid(false);
  };

  return (
    <>
      {heroImage && <SmallHeroBlock image={heroImage} />}
      <PageContainer>
        <Row>
          {image && (
            <ImageWrapper>
              <Image
                src={
                  image.src +
                  (isMobile
                    ? '?preset=event-block-mobile'
                    : '?preset=event-page-desktop')
                }
                alt="image"
              />
            </ImageWrapper>
          )}
          <TextContainer>
            <H4 css={HeadingStyle}>{heading}</H4>
            <DateAndTagsDiv>
              <DateSpan>{startDate}</DateSpan>
              {endDate !== startDate && (
                <DateSpan>
                  {to} {endDate}
                </DateSpan>
              )}
            </DateAndTagsDiv>
            <DateAndTagsDiv>
              <DateSpan>{startTime + ' -'}</DateSpan>
              <DateSpan>{' ' + endTime}</DateSpan>
            </DateAndTagsDiv>
            <DateAndTagsDiv>
              <HtmlArea
                content={location}
                css={{
                  fs: 8,
                  '@mediaMinLarge': {
                    fs: 6,
                  },
                }}
              />
            </DateAndTagsDiv>
            {hasSubmitted && <SubmissionText>{eventSubmitted}</SubmissionText>}
            {showSignUp && !isFullyBooked && (
              <>
                <InputValidation
                  onInputsValid={onInputsValid}
                  onInputsInvalid={onInputsInvalid}
                >
                  <Input
                    title={givenName}
                    placeholder={givenName}
                    autoComplete="given-name"
                    name="name"
                    onChange={setName}
                    defaultValue={name}
                    validation={{
                      required: true,
                      errorMessage: validationLabels.isRequired,
                    }}
                  />
                  <Input
                    title={emailLabel}
                    placeholder={emailLabel}
                    autoComplete="email"
                    name="email"
                    onChange={setEmail}
                    defaultValue={email}
                    validation={{
                      ...accountFormValidation.email,
                      errorMessage: validationLabels.invalidEmail,
                    }}
                  />
                  <Input
                    title={phoneNumber}
                    placeholder={phoneNumber}
                    autoComplete="phone"
                    name="phone"
                    onChange={setPhoneNbr}
                    defaultValue={phoneNbr}
                    validation={{
                      ...accountFormValidation.phoneNumber,
                      errorMessage: validationLabels.invalidPhoneNumber,
                    }}
                  />
                </InputValidation>
                <ButtonWrapper>
                  <Button
                    fullWidth
                    onClick={SignUpForEvent}
                    disabled={!formValid}
                  >
                    {signUp}
                  </Button>
                </ButtonWrapper>
              </>
            )}
            {isFullyBooked && (
              <Paragraph size="l">{isFullyBookedText}</Paragraph>
            )}
          </TextContainer>
        </Row>
        <Row>
          <SubheadingText>
            <H4 css={HeadingStyle}>{subheading}</H4>
          </SubheadingText>
        </Row>
        <Row>
          <IntroText
            {...applyEditModeAttr(inEditMode && 'IntroText')}
            dangerouslySetInnerHTML={{
              __html: introText,
            }}
          />
        </Row>
        <Row>
          <BodyText
            {...applyEditModeAttr(inEditMode && 'BodyText')}
            dangerouslySetInnerHTML={{
              __html: bodyText,
            }}
          />
        </Row>
      </PageContainer>
    </>
  );
}

const ImageWrapper = styled('div', {
  h: '238px',
  '@mediaMinMedium': {
    h: '376px',
  },
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

const SubmissionText = styled('p', {
  fs: 6,
  color: '$JE68GreenPrimary',
});

const ButtonWrapper = styled('div', {
  width: '100%',
  mt: 6,
  '@mediaMinLarge': {
    mt: 'auto',
  },
});

const Row = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  '@mediaMinLarge': {
    flexDirection: 'row',
  },
});

const TextContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  py: 4,
  pb: 0,
  '@mediaMinLarge': {
    p: 8,
    pb: 0,
    w: '100%',
    maxW: '336px',
  },
});

const PageContainer = styled('div', {
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: '$primary6',
  position: 'relative',
  top: -92,
  mx: 4,
  '@mediaMinLarge': {
    maxWidth: '864px',
    backgroundColor: '$primary5',
    mb: 30,
    mx: 'auto',
  },
});

const HeadingStyle = { mb: 4 };

const DateAndTagsDiv = styled('div', {
  display: 'flex',
  fs: 8,
  ls: '$ls06',
  mb: 2,
  '@mediaMinLarge': {
    fs: 6,
  },
});

const SubheadingText = styled('div', {
  lineHeight: '$lh1625',
  ls: '$ls06',
  color: '$secondary4',
  maxW: 160,
  pt: 12,
  '@mediaMinLarge': {
    px: 8,
  },
});

const IntroText = styled('div', {
  lineHeight: '$lh1625',
  ls: '$ls06',
  color: '$secondary4',
  maxW: 160,
  '@mediaMinLarge': {
    px: 8,
  },
  'ol, ul': {
    listStyle: 'initial',
    my: 4,
    pl: 10,
  },
});

const BodyText = styled('div', {
  lineHeight: '$lh1625',
  ls: '$ls06',
  color: '$secondary4',
  maxW: 160,
  pt: 2,
  pb: 12,
  '@mediaMinLarge': {
    px: 8,
  },
  'ol, ul': {
    listStyle: 'initial',
    my: 4,
    pl: 10,
  },
});

const DateSpan = styled('p', {
  mr: 1,
  fs: 8,
  '@mediaMinLarge': {
    fs: 6,
  },
});

export default EventPage;
